import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Suspense, lazy } from "react";
import { ConfirmProvider } from "hooks/confirm/confirm.provider";

const AdminLayout = lazy(() => import('layouts/admin'));
const AuthLayout = lazy(() => import('layouts/auth'));
const WarningLayout = lazy(() => import('layouts/warning'));
const WithoutAuthLayout = lazy(() => import('layouts/withoutAuth'));

const router = createBrowserRouter([
  {
    path: "auth/*",
    element: <AuthLayout />,
  },
  {
    path: "admin/*",
    element: <AdminLayout />,
  },
  {
    path: "server/*",
    element: <WarningLayout />,
  },
  {
    path: "products/*",
    element: <WithoutAuthLayout />,
  },
  {
    path: "/",
    element: <Navigate to="/auth" replace />,
  },
]);
const App = () => {
  return (
    <ConfirmProvider>
      <Suspense fallback={<div></div>}>
          <RouterProvider router={router} />
      </Suspense>
    </ConfirmProvider>
    
  );
};

export default App;
